import { Injectable } from '@angular/core';
import { DomainModel } from '@models/users/domain.model';
import { UserRegisterRequest } from '@models/users/dto/user-register.request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageListingResponse } from '@models/api/page-listing-response.model';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { User } from '@models/users/user.model';
import { UpdateUserRequest } from '@models/users/dto/update-user.request';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';
import { UserProfileInfo } from '@models/users/dto/user-profile-info.response';
import { EmailSender } from '@models/users/email-sender.model';
import { CreateSenderRequest } from '@models/users/dto/create-sender.request';
import { VerifySenderUsingOTPRequest } from '@models/users/dto/verify-sender-OTP.request';
import { CreateDomainRequest } from '@models/users/dto/create-domain.request';
import { EmailDomain } from '@models/users/email-domain.model';
import { GlobalSettings } from '@models/users/global_settings.model';
import { GlobalSettingsRequest } from '@models/users/dto/global_settings.request';
import { UtmParams } from '@models/shared/utm-params.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService) {}

  getUserDetailsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<User>> {
    return this.api
      .getPaged(`${env.api}/users/paged`, pageNumber, pageSize, filters)
      .pipe(map((it) => this.mapUserDetails(it)));
  }

  private mapUserDetails(res: PageListingResponse<User>) {
    return {
      total: res.total,
      records: res.records.map((it) => new User(it)),
    };
  }

  getPartners(): Observable<User[]> {
    return this.api.get<User[]>(`${env.api}/users/partners`);
  }

  registerFromCommunity(request: UserRegisterRequest): Observable<User> {
    return this.api
      .post<User>(`${env.api}/register`, request)
      .pipe(map((it) => new User(it)));
  }

  register(
    email?: string,
    password?: string,
    name?: string,
    language?: string,
    utmParams?: UtmParams,
    referralCode?: string,
  ): Observable<User> {
    return this.api
      .post<User>(`${env.api}/register`, {
        email,
        password,
        name,
        language,
        utmParams,
        referralCode,
      })
      .pipe(map((it) => new User(it)));
  }

  registerWithPhone(
    phone?: string,
    name?: string,
    language?: string,
    utmParams?: UtmParams,
    referralCode?: string,
  ): Observable<User> {
    return this.api
      .post<User>(`${env.api}/register`, {
        phone,
        name,
        language,
        utmParams,
        referralCode,
      })
      .pipe(map((it) => new User(it)));
  }

  update(req: UpdateUserRequest): Observable<User> {
    return this.api
      .put<User>(`${env.api}/users`, req)
      .pipe(map((it) => new User(it)));
  }

  findByEmailOrPhone(req: Map<string, any>): Observable<User> {
    return this.api
      .get(`${env.api}/users/find-by-credentials`, req)
      .pipe(map((it) => new User(it)));
  }

  createOrFindUser(req?: NewUserCreationRequest): Observable<number> {
    return this.api.post<number>(`${env.api}/users/create-or-find-user`, req);
  }

  findUser(req?: NewUserCreationRequest): Observable<number> {
    return this.api.post<number>(`${env.api}/users/find-user`, req);
  }

  delete(userId: number): Observable<void> {
    return this.api.delete(`${env.api}/users/${userId}`);
  }

  getUserProfileInfo(userId: number): Observable<UserProfileInfo> {
    return this.api.get<UserProfileInfo>(
      `${env.api}/users/${userId}/profile-info`,
    );
  }

  getReferredUsers(userId: number): Observable<User[]> {
    return this.api
      .get<User[]>(`${env.api}/users/${userId}/referred-users`)
      .pipe(map(this.mapUsers));
  }

  checkReferralCodeExists(referralCode: string): Observable<boolean> {
    return this.api.get<boolean>(
      `${env.api}/users/check-referral-code/${referralCode}`,
    );
  }

  private mapUsers(res: User[]) {
    return res?.map((it) => new User(it));
  }

  getSenders(): Observable<EmailSender[]> {
    return this.api.get<EmailSender[]>(`${env.api}/users/senders`);
  }

  createSender(req: CreateSenderRequest): Observable<EmailSender> {
    return this.api.post(`${env.api}/users/senders`, req);
  }

  updateSender(
    senderId: number,
    req: CreateSenderRequest,
  ): Observable<EmailSender> {
    return this.api.put(`${env.api}/users/senders/${senderId}/update`, req);
  }

  validateSender(
    senderId: number,
    req: VerifySenderUsingOTPRequest,
  ): Observable<boolean> {
    return this.api.put(`${env.api}/users/senders/${senderId}/validate`, req);
  }

  resendSenderVerificationCode(senderId: number): Observable<EmailSender> {
    return this.api.post(
      `${env.api}/users/senders/${senderId}/resend-verification-code`,
      {},
    );
  }

  deleteSender(senderId: number): Observable<void> {
    return this.api.delete(`${env.api}/users/senders/${senderId}`);
  }

  getDomains(): Observable<DomainModel[]> {
    return this.api.get<DomainModel[]>(`${env.api}/users/domains`);
  }

  createDomain(req: CreateDomainRequest): Observable<EmailDomain> {
    return this.api.post(`${env.api}/users/domains`, req);
  }

  deleteDomain(domainId: string): Observable<void> {
    return this.api.delete(`${env.api}/users/domains/${domainId}`);
  }

  validateDomain(req: CreateDomainRequest): Observable<boolean> {
    return this.api.put(`${env.api}/users/domains/validate`, req);
  }

  getGlobalSettings(): Observable<GlobalSettings> {
    return this.api
      .get<GlobalSettings>(`${env.api}/users/global-settings`)
      .pipe(map((i) => new GlobalSettings(i)));
  }

  setGlobalSettings(req: GlobalSettingsRequest): Observable<GlobalSettings> {
    return this.api
      .post(`${env.api}/users/global-settings/update`, req)
      .pipe(map((i) => new GlobalSettings(i)));
  }
}
